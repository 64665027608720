const langText: Record<string, any> = {
    au: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    us: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    ca: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    cis: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    cn: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    de: {
        user_popover: {
            desc: 'Sie erhalten 500 EcoCredits im Wert von 25 €',
            button: 'Jetzt registrieren',
            register_tip: 'Bereits Mitglied? ',
            login: 'Einloggen',
        },
    },
    en: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    es: {
        user_popover: {
            desc: 'Obtén 500 EcoCredits, con un valor de 25 €',
            button: 'Únete ahora',
            register_tip: '¿Ya tienes una cuenta? ',
            login: 'Inicia sesión',
        },
    },
    eu: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    fr: {
        user_popover: {
            desc: "Obtenez 500 EcoCredits d'une valeur de 25 €",
            button: 'Rejoignez-nous maintenant',
            register_tip: 'Vous avez déjà un compte? ',
            login: 'Se connecter',
        },
    },
    hk: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    it: {
        user_popover: {
            desc: 'Ricevi 500 EcoCredits del valore di 25 €',
            button: 'Unisciti ora',
            register_tip: 'Hai già un account? ',
            login: 'Accedi',
        },
    },
    jp: {
        user_popover: {
            desc: '500 エコポイント をゲット',
            button: '今すぐ登録する',
            register_tip: 'すでにアカウントをお持ちですか？ ',
            login: 'ログイン',
        },
    },
    kr: {
        user_popover: {
            desc: '500 에코포인트(₩35,000 상당)를 받으세요',
            button: '지금 가입하기',
            register_tip: '기존 사용자이신가요? ',
            login: '로그인',
        },
    },
    sa: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    tw: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    uk: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth £25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
    za: {
        user_popover: {
            desc: 'Get 500 EcoCredits, Worth R500',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    },
};

export default langText;
