import { langText } from '../langText';
import { IconPrev } from '../icon';
import getLocal from '@/app/utils/getLocal';

export default function SectionC({ switchHandle }: any) {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];

    return (
        <div className="EcoCreditsWin-Section">
            <div className="EcoCreditsWin-title">{TextA.section_d_title}</div>
            <div className="EcoCreditsWin-desc">{TextA.section_d_desc}</div>
            <div className="EcoCreditsWin-link" onClick={() => switchHandle('g')}>
                {TextA.LearnMore}
                <IconPrev />
            </div>
        </div>
    );
}
