import EditorLink from 'lowcode/components/EditorLink';
import styles from '../index.module.scss';
import { langText } from '../langText';

export default function FooterA({ openType, locale, isLogin }: any) {
    const TextA = langText[locale] || langText['us'];
    const joinNowLink = `https://www.ecoflow.com/${locale}/register?redirect=https://www.ecoflow.com/${locale}/ecocredits`;
    const referNowLink = `${locale}/referral`;
    const loginNowLink = `https://www.ecoflow.com/${locale}/login?redirect=https://www.ecoflow.com/${locale}/ecocredits`;

    const titleNameMap: any = {
        b: 'section_a_title',
        c: 'section_a_title',
        d: 'section_b_title',
        e: 'section_c_title',
        f: 'section_a_title',
        g: 'section_d_title',
    };

    return (
        <div className={styles.FooterA}>
            <div className="FooterA-title">{TextA.footer_title}</div>
            <EditorLink
                className="FooterA-btn"
                href={openType === 'e' ? referNowLink : joinNowLink}
                dataLayer={
                    openType === 'e'
                        ? {
                              event: 'uaEvent',
                              event_name: 'brand_floating_interaction',
                              button_name: TextA.footer_btn2,
                              function: 'Referral',
                              title_name: TextA.win5_title,
                          }
                        : {
                              event: 'uaEvent',
                              event_name: 'brand_floating_interaction',
                              button_name: TextA.footer_btn,
                              function: 'Register',
                              title_name: TextA[titleNameMap[openType]] || 'Entry',
                          }
                }
            >
                {openType === 'e' ? TextA.footer_btn2 : TextA.footer_btn}
            </EditorLink>
            {!isLogin && (
                <div className="FooterA-tips">
                    {TextA.footer_no_login_tips1}
                    <EditorLink
                        href={loginNowLink}
                        dataLayer={{
                            event: 'uaEvent',
                            event_name: 'brand_floating_interaction',
                            button_name: TextA.footer_no_login_tips2,
                            function: 'Log in',
                            title_name: TextA[titleNameMap[openType]] || 'Entry',
                        }}
                    >
                        {TextA.footer_no_login_tips2}
                    </EditorLink>
                </div>
            )}
        </div>
    );
}
