import EfEmarsysEmail from '@elem/ui/ef-emarsys-email';
import { Icond1, Icond2, Icond3 } from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';

export default function WinD() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];

    const listEvent = [
        {
            icon: <Icond1 />,
            title: TextA.win4_item1,
        },
        {
            icon: <Icond2 />,
            title: TextA.win4_item2,
        },
        {
            icon: <Icond3 />,
            title: TextA.win4_item3,
        },
    ];

    const EmailConfigMap: any = {
        us: {
            contactListId: '296237655',
            fields: [
                {
                    id: '9435',
                    type: 'multiple',
                    value: [14],
                    overwrite: true,
                },
            ],
        },
        ca: {
            contactListId: '290606945',
            fields: [
                {
                    id: '9435',
                    type: 'multiple',
                    value: [14],
                    overwrite: true,
                },
            ],
        },
        jp: {
            contactListId: '396526985',
            fields: [
                {
                    id: '31',
                    type: 'single',
                    value: 1,
                    overwrite: true,
                },
                {
                    id: '4431',
                    type: 'single',
                    value: 1,
                    overwrite: true,
                },
                {
                    id: '9422',
                    type: 'multiple',
                    value: [13],
                    overwrite: true,
                },
            ],
        },
        au: {
            contactListId: '1851104552',
            fields: [
                {
                    id: '9896',
                    type: 'single',
                    value: 1,
                    overwrite: true,
                },
            ],
        },
        za: {
            contactListId: '1662148634',
            fields: [
                {
                    id: '8916',
                    type: 'multiple',
                    value: [18],
                    overwrite: false,
                },
            ],
        },
        kr: {
            contactListId: '1575239412',
            fields: [
                {
                    id: '4432',
                    type: 'single',
                    value: 1,
                    overwrite: true,
                },
                {
                    id: '9120',
                    type: 'single',
                    value: 9,
                    overwrite: true,
                },
            ],
        },
        eu: {
            contactListId: '376217394',
            fields: [
                {
                    id: '7596',
                    type: 'multiple',
                    value: [11],
                    overwrite: false,
                },
            ],
        },
    };

    const config = EmailConfigMap[locale] || EmailConfigMap['eu'];

    return (
        <div className={styles.WinD}>
            <div className="WinD-sub-title">{TextA.win4_sub_title}</div>
            <div className="WinD-list">
                {listEvent.map((item: any, index: number) => (
                    <div className="WinD-item" key={index}>
                        <div className="WinD-item-l">{item.icon}</div>
                        <div className="WinD-item-r">{item.title}</div>
                    </div>
                ))}
            </div>
            <div className="WinD-email">
                <EfEmarsysEmail
                    custom_gtm
                    needTerms={true}
                    contactListId={config?.contactListId}
                    fields={config?.fields}
                    callback={(tag, btnText) => {
                        gtm.push({
                            event: 'uaEvent',
                            event_name: 'brand_floating_subscription',
                            button_name: btnText,
                            title_name: TextA.win4_sub_title,
                            status: tag,
                        });
                    }}
                />
            </div>
        </div>
    );
}
