// 根据script的src添加script标签
export const appendScriptToHead = (src: string) => {
    if (typeof window !== 'undefined') {
        const waitAdd = document.createElement('script');
        waitAdd.type = 'text/javascript';
        waitAdd.async = true;
        waitAdd.defer = true;
        waitAdd.src = src;
        document.head.appendChild(waitAdd);
    }
};

// 根据script的 content 添加script标签
export const appendScriptContentToHead = (text: string) => {
    if (typeof window !== 'undefined') {
        const waitAdd = document.createElement('script');
        waitAdd.type = 'text/javascript';
        waitAdd.async = true;
        waitAdd.defer = true;
        waitAdd.innerHTML = text;
        console.log('waitAdd', text, waitAdd);
        document.head.appendChild(waitAdd);
    }
};
