// import EditorLink from 'lowcode/components/EditorLink';
import { Icone1, Icone2, Icone3 } from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

export default function WinE() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const listA = [
        {
            icon: <Icone1 />,
            title: TextA.win5_item1,
        },
        {
            icon: <Icone2 />,
            title: TextA.win5_item2,
        },
        {
            icon: <Icone3 />,
            title: TextA.win5_item3,
        },
    ];

    return (
        <div className={styles.WinE}>
            {listA.map((item: any, index: number) => (
                <div key={index} className="WinE-item">
                    <div className="WinE-item-l">{item.icon}</div>
                    <div className="WinE-item-r">{item.title}</div>
                </div>
            ))}
            {['jp'].includes(locale) && <p className="winE-tips">{TextA.win5_tips}</p>}
            {/* {['za'].includes(locale) && (
                <EditorLink href={`${locale}/referral`} className="winE-btn">
                    {TextA.LearnMore}
                </EditorLink>
            )} */}
        </div>
    );
}
