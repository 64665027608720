import { Icon } from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

export default function WinF() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const listA = [
        {
            icon: <Icon />,
            title: TextA.win6_item1,
        },
        {
            icon: <Icon />,
            title: TextA.win6_item2,
        },
    ];

    return (
        <div className={styles.WinF}>
            <p className="WinF-desc">{TextA.win6_desc}</p>
            <p className="WinF-text">{TextA.win6_text}</p>
            {listA.map((item: any, index: number) => (
                <div key={index} className="WinF-item">
                    <div className="WinF-item-l">{item.icon}</div>
                    <div className="WinF-item-r">{item.title}</div>
                </div>
            ))}
        </div>
    );
}
