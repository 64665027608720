import { Helmet } from 'react-helmet';
import Head from 'next/head';

/**
 * 优化seo抓取不同语言站点
 * @returns link[]
 */

// const mapobj: any = {
//     us: 'en-US',
//     uk: 'en-GB',
//     ca: 'en-CA',
//     au: 'en-AU',
//     jp: 'ja-JP',
//     kr: 'ko-KR',
//     de: 'de-DE',
//     fr: 'fr-FR',
//     cn: 'zh-CN',
//     it: 'it-IT',
//     es: 'es-ES',
//     za: 'af-ZA',
//     ru: 'ru-RU',
//     cis: 'ru-RU',
// };

const mapobj: any = {
    // eu = en   us = en-us
    us: 'en',
    uk: 'en-gb',
    ca: 'en-ca',
    au: 'en-au',
    jp: 'ja',
    kr: 'kr',
    de: 'de',
    fr: 'fr',
    cn: 'zh',
    it: 'it',
    es: 'es',
    za: 'en-za',
    ru: 'ru',
    cis: 'ru',
    sa: 'ar-sa',
    tw: 'zh-tw',
};

export default function SEOlinks({ locale = 'us', list = [], pathname = '' }: { locale: any; list: string[]; pathname: string }) {
    const base = 'https://www.ecoflow.com';
    let canonical = `${base}/${locale}/${pathname}`;
    if (!pathname) {
        canonical = `${base}/${locale}`;
    }
    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical} />
                {list?.map((item: any) => (
                    <link key={item} rel="alternate" href={pathname ? `${base}/${item}/${pathname}` : `${base}/${item}`} hrefLang={mapobj[item]} />
                ))}
            </Helmet>
            {list.includes('sa') && (
                <Head>
                    <link rel="alternate" href={pathname ? `${base}/sa/${pathname}` : `${base}/sa`} hrefLang="ar-ae" key="ar-ae" />
                    {/* <link rel="alternate" href="https://www.ecoflow.com/sa" hrefLang="ar-sa" key="ar-sa" /> */}
                </Head>
            )}
        </>
    );
}
