import { useState } from 'react';
import { IconbRaS, IconbShop, IconbRaF, IconbWarranty, IconbProfile, IconPrev, IconbIDCard, IconbEmail, IconbCart, IconbTwoHm, IconbPaper } from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

// 副弹窗 B
export default function WinB() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const [renderKey, setRenderKey] = useState(0);
    const iconMap: any = {
        us: [<IconbRaS key="1" />, <IconbShop key="2" />, <IconbRaF key="3" />, <IconbWarranty key="4" />, <IconbProfile key="5" />],
        jp: [<IconbIDCard key="1" />, <IconbEmail key="2" />, <IconbCart key="3" />, <IconbTwoHm key="4" />, <IconbWarranty key="5" />, <IconbPaper key="6" />],
    };
    const icons = iconMap[locale] || iconMap['us'];
    const EarnMap: any = {
        us: [
            {
                isOpen: true,
                icon: icons[0],
                title: TextA.win2_item1_title,
                score: TextA.win2_item1_score,
                desc: TextA.win2_item1_desc,
            },
            {
                isOpen: false,
                icon: icons[1],
                title: TextA.win2_item2_title,
                score: TextA.win2_item2_score,
                desc: TextA.win2_item2_desc,
            },
            {
                isOpen: false,
                icon: icons[2],
                title: TextA.win2_item3_title,
                score: TextA.win2_item3_score,
                desc: TextA.win2_item3_desc,
            },
            {
                isOpen: false,
                icon: icons[3],
                title: TextA.win2_item4_title,
                score: TextA.win2_item4_score,
                desc: TextA.win2_item4_desc,
            },
            {
                isOpen: false,
                icon: icons[4],
                title: TextA.win2_item5_title,
                score: TextA.win2_item5_score,
                desc: TextA.win2_item5_desc,
            },
        ],
        jp: [
            {
                isOpen: true,
                icon: icons[0],
                title: TextA.win2_item1_title,
                score: TextA.win2_item1_score,
                desc: TextA.win2_item1_desc,
            },
            {
                isOpen: false,
                icon: icons[1],
                title: TextA.win2_item2_title,
                score: TextA.win2_item2_score,
                desc: TextA.win2_item2_desc,
            },
            {
                isOpen: false,
                icon: icons[2],
                title: TextA.win2_item3_title,
                score: TextA.win2_item3_score,
                desc: TextA.win2_item3_desc,
            },
            {
                isOpen: false,
                icon: icons[3],
                title: TextA.win2_item4_title,
                score: TextA.win2_item4_score,
                desc: TextA.win2_item4_desc,
            },
            {
                isOpen: false,
                icon: icons[4],
                title: TextA.win2_item5_title,
                score: TextA.win2_item5_score,
                desc: TextA.win2_item5_desc,
            },
            {
                isOpen: false,
                icon: icons[5],
                title: TextA.win2_item6_title,
                score: TextA.win2_item6_score,
                desc: TextA.win2_item6_desc,
            },
        ],
    };
    const earns = EarnMap[locale] || EarnMap['us'];
    const [listEarn, setListEarn] = useState(earns);

    const toggleHandle = (item: any, index: number) => {
        const isOpen2 = item.isOpen;
        const listA2 = listEarn;
        listA2[index].isOpen = !isOpen2;
        setListEarn(listA2);
        setRenderKey(Math.random());
    };

    return (
        <div className={styles.WinB}>
            {listEarn.map((item: any, index: number) => (
                <div className={`WinB-item${item.isOpen ? ' WinB-item-open' : ''}`} key={index + renderKey} onClick={() => toggleHandle(item, index)}>
                    <div className="WinB-line1">
                        <span className="WinB-icon">{item.icon}</span>
                        <div className="WinB-r">
                            <div className="WinB-title">
                                {item.title}
                                <IconPrev />
                            </div>
                            <div className="WinB-score">{item.score}</div>
                        </div>
                    </div>
                    <div className="WinB-desc">{item.desc}</div>
                </div>
            ))}
        </div>
    );
}
