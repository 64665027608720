import { useEffect, useState } from 'react';
import FooterA from './comps/FooterA';
import { Icon, IconBack, IconClose } from './icon';
import styles from './index.module.scss';
import { langText } from './langText';
import WinA from './win/WinA';
import WinB from './win/WinB';
import WinC from './win/WinC';
import WinD from './win/WinD';
import WinE from './win/WinE';
import WinF from './win/WinF';
import WinG from './win/WinG';
import getLocal from '@/app/utils/getLocal';
import { getIsLogin } from '@/utils/cookie';
import gtm from '@/utils/gtm';

export default function EcoCreditsWin() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const [openType, setOpenType] = useState('a');
    const [isOpen, setIsOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const titleNameMap: any = {
        b: 'section_a_title', // how to
        c: 'section_a_title', // get rewards
        d: 'section_b_title', // EcoCredits
        e: 'section_c_title', // Referral
        f: 'section_a_title', // jp
        g: 'section_d_title', // za Join Community
    };

    const functionMap: any = {
        d: 'EcoCredits',
        e: 'Referral',
        g: 'Join Community',
    };

    // 切换
    const toggleHandle = () => {
        if (!isOpen) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'floating_button',
                button_name: 'Web EF icon',
            });
        }
        setIsOpen(!isOpen);
        setOpenType('a');
    };

    // 切换子弹窗
    const switchHandle = (type: string) => {
        setOpenType(type);
        ['d', 'e', 'g'].includes(type) &&
            gtm.push({
                event: 'uaEvent',
                event_name: 'brand_floating_interaction',
                button_name: TextA.LearnMore,
                function: functionMap[type],
                title_name: TextA[titleNameMap[type]] || 'Entry',
            });
    };

    useEffect(() => {
        setIsLogin(getIsLogin());
    }, []);

    return (
        <>
            <div className={styles.topIcon} onClick={toggleHandle}>
                <div className="win-icon">
                    <Icon />
                </div>
            </div>
            <div
                className={[
                    styles.dialogWin,
                    styles.dialogWinCommon,
                    `dialogWin-type-${openType}`,
                    `${isOpen ? 'dialogWinOpen' : ''}`,
                    `${isLogin ? 'is-login' : ''}`,
                ].join(' ')}
            >
                <div className="EcoCreditsWin-header">
                    <span className="EcoCreditsWin-header-back" onClick={() => switchHandle('a')}>
                        <IconBack />
                    </span>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-a"></div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-b">{TextA.win2_title}</div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-c">{TextA.win3_title}</div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-d">{TextA.win4_title}</div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-e">{TextA.win5_title}</div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-f">{TextA.win6_title}</div>
                    <div className="EcoCreditsWin-header-ms EcoCreditsWin-header-ms-g">{TextA.win7_title}</div>
                    <span className="EcoCreditsWin-close" onClick={() => setIsOpen(false)}>
                        <IconClose />
                    </span>
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-a">
                    <WinA switchHandle={switchHandle} locale={locale} />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-b">
                    <WinB />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-c">
                    <WinC />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-d">
                    <WinD />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-e">
                    <WinE />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-f">
                    <WinF />
                </div>
                <div className="EcoCreditsWin-body EcoCreditsWin-body-g">
                    <WinG />
                </div>
                {(openType == 'e' || !isLogin) && <FooterA openType={openType} locale={locale} isLogin={isLogin} />}
            </div>
        </>
    );
}
