import { IconaEarnEC, IconaGetRwd, IconPrev, IconaCart, IconaGift } from '../icon';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

export default function SectionA({ switchHandle }: any) {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];

    const iconsMap: any = {
        us: [<IconaEarnEC key="1" />, <IconaGetRwd key="2" />],
        jp: [<IconaEarnEC key="1" />, <IconaGift key="2" />, <IconaCart key="3" />],
    };
    const icons = iconsMap[locale] || iconsMap['us'];

    return (
        <div className="EcoCreditsWin-Section">
            <div className="EcoCreditsWin-title">{TextA.section_a_title}</div>
            <div className="EcoCreditsWin-desc">{TextA.section_a_desc}</div>
            <div className="EcoCreditsWin-list">
                <div className="EcoCreditsWin-item" onClick={() => switchHandle?.('b')}>
                    <div className="EcoCreditsWin-item-icon">{icons[0]}</div>
                    <div className="EcoCreditsWin-item-info">{TextA.section_a_item1}</div>
                    <IconPrev />
                </div>
                {['jp'].includes(locale) && ( // 3
                    <>
                        <div className="EcoCreditsWin-item" onClick={() => switchHandle?.('f')}>
                            <div className="EcoCreditsWin-item-icon">{icons[2]}</div>
                            <div className="EcoCreditsWin-item-info">{TextA.section_a_item3}</div>
                            <IconPrev />
                        </div>
                    </>
                )}
                <div className="EcoCreditsWin-item" onClick={() => switchHandle?.('c')}>
                    <div className="EcoCreditsWin-item-icon">{icons[1]}</div>
                    <div className="EcoCreditsWin-item-info">{TextA.section_a_item2}</div>
                    <IconPrev />
                </div>
            </div>
        </div>
    );
}
