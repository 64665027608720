'use client';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';
import { getIsLogin } from 'lowcode/utils/getIsLogin';
import { useEffect, useState } from 'react';
import styles2 from './index.module.scss';
import lang from './lang';

export default function IndexLoginTips() {
    const { locale } = getLocal();
    const [isLogin, setIsLogin] = useState(false);
    const TextA = lang[locale] || lang['us'];
    const t = TextA.user_popover;

    useEffect(() => {
        setIsLogin(getIsLogin());
    }, []);

    return (
        <>
            {!isLogin && (
                <div className={styles2.entry}>
                    <div className="entry-text">
                        <div className="text">{t['desc']}</div>
                        <div className="tip">
                            {t['register_tip']}
                            <span
                                className="text-link"
                                onClick={() => {
                                    if (typeof window !== 'undefined') {
                                        const locationHref = location.href;
                                        gtm.push({
                                            event: 'uaEvent',
                                            event_name: 'authentication_button',
                                            type: 'Redirect',
                                            button_name: t['login'],
                                            url: `https://www.ecoflow.com/${locale}/login?redirect=${encodeURIComponent(locationHref)}`,
                                        });
                                        setTimeout(() => {
                                            window.location.href = `/${locale}/login?redirect=${encodeURIComponent(locationHref)}`;
                                        });
                                    }
                                }}
                            >
                                {t['login']}
                            </span>
                        </div>
                    </div>
                    <button
                        className="button-register"
                        onClick={() => {
                            if (typeof window !== 'undefined') {
                                const locationHref = location.href;
                                gtm.push({
                                    event: 'uaEvent',
                                    event_name: 'authentication_button',
                                    type: 'Redirect',
                                    button_name: t['button'],
                                    url: `https://www.ecoflow.com/${locale}/register?redirect=${encodeURIComponent(locationHref)}`,
                                });
                                setTimeout(() => {
                                    window.location.href = `/${locale}/register?redirect=${encodeURIComponent(locationHref)}`;
                                });
                            }
                        }}
                    >
                        {t['button']}
                    </button>
                </div>
            )}
        </>
    );
}
