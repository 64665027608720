import SectionA from '../comps/SectionA';
import SectionB from '../comps/SectionB';
import SectionC from '../comps/SectionC';
import SectionD from '../comps/SectionD';

// 主弹窗
export default function WinMain({ switchHandle, locale }: any) {
    return (
        <>
            <SectionA switchHandle={switchHandle} />
            {!['jp'].includes(locale) && <SectionB switchHandle={switchHandle} />}
            {['za'].includes(locale) && <SectionD switchHandle={switchHandle} />}
            <SectionC switchHandle={switchHandle} />
        </>
    );
}
