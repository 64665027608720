import EditorLink from 'lowcode/components/EditorLink';
import { IconfCar, IconfTent, IconfChat } from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

export default function WinG() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const listA = [
        {
            icon: <IconfCar />,
            title: TextA.win7_item1,
        },
        {
            icon: <IconfTent />,
            title: TextA.win7_item2,
        },
        {
            icon: <IconfChat />,
            title: TextA.win7_item3,
        },
    ];

    return (
        <div className={styles.WinG}>
            {listA.map((item: any, index: number) => (
                <div key={index} className="WinG-item">
                    <div className="WinG-item-l">{item.icon}</div>
                    <div className="WinG-item-r">{item.title}</div>
                </div>
            ))}
            <EditorLink className="winG-btn" href="https://www.facebook.com/groups/efsouthafrica">
                {TextA.win7_btn}
            </EditorLink>
        </div>
    );
}
