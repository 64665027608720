export const langText: any = {
    us: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        //第2段落
        section_b_title: 'EcoCredits Monthly Madness',
        section_b_desc: 'Monthly Thrills, Epic Rewards Only for a Limited Time!\nHappening every fourth Thursday and Friday of the month.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Give a 5% off coupon, and earn up to $500 in cash per referral.',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every $1 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '$5',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '$10',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '$25',
        win3_a_item4_title: '1000 EcoCredits',
        win3_a_item4_score: '$50',
        win3_a_item5_title: '2000 EcoCredits',
        win3_a_item5_score: '$100',
        win3_a_item6_title: '4000 EcoCredits',
        win3_a_item6_score: '$200',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Caps',
        win3_b_item1_score: '2949 EcoCredits',
        win3_b_item2_title: 'EcoFlow Waterproof Picnic Blanket',
        win3_b_item2_score: '3949 EcoCredits',
        win3_b_item3_title: 'EcoFlow Camping Light',
        win3_b_item3_score: '5449 EcoCredits',
        win3_b_item4_title: 'EcoFlow DELTA Pro Bag',
        win3_b_item4_score: '6949 EcoCredits',
        win3_b_item5_title: 'EcoFlow Camping Chair',
        win3_b_item5_score: '7449 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Day',
        win4_sub_title: 'Highlights of the EcoCredits Day event:',
        win4_item1: '48-hour member-only discounts',
        win4_item2: 'Redeem coupons and gifts with \nhalf-EcoCredits: limited availability!',
        win4_item3: 'More discounts are waiting for you to explore!\nNever miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Win-Win Referral Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Enjoy 5% cash back on \nevery referred order.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',
    },
    ca: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        //第2段落
        section_b_title: 'EcoCredits Monthly Madness',
        section_b_desc: 'Monthly Thrills, Epic Rewards Only for a Limited Time!\nHappening every fourth Thursday and Friday of the month.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Give a 5% off coupon, and earn up to $500 in cash per referral.',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every $1 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '$5',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '$10',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '$25',
        win3_a_item4_title: '1000 EcoCredits',
        win3_a_item4_score: '$50',
        win3_a_item5_title: '2000 EcoCredits',
        win3_a_item5_score: '$100',
        win3_a_item6_title: '4000 EcoCredits',
        win3_a_item6_score: '$200',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Key Chain',
        win3_b_item1_score: '3449 EcoCredits',
        win3_b_item2_title: 'EcoFlow Waterproof Picnic Blanket',
        win3_b_item2_score: '4449 EcoCredits',
        win3_b_item3_title: 'EcoFlow Camping Light',
        win3_b_item3_score: '6449 EcoCredits',
        win3_b_item4_title: 'EcoFlow DELTA Pro Bag',
        win3_b_item4_score: '8949 EcoCredits',
        win3_b_item5_title: 'EcoFlow Camping Chair',
        win3_b_item5_score: '9449 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Day',
        win4_sub_title: 'Highlights of the EcoCredits Day event:',
        win4_item1: '48-hour member-only discounts',
        win4_item2: 'Redeem coupons and gifts with \nhalf-EcoCredits: limited availability!',
        win4_item3: 'More discounts are waiting for you to explore!\nNever miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Win-Win Referral Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Enjoy 5% cash back on \nevery referred order.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',
    },
    au: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        //第2段落
        section_b_title: 'EcoCredits Appreciation Day',
        section_b_desc: 'Happening every 5-6 weeks.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Give a 5% off coupon, and earn up to $500 in cash per referral.',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every $1 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '$5',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '$10',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '$25',
        win3_a_item4_title: '700 EcoCredits',
        win3_a_item4_score: '$35',
        win3_a_item5_title: '1000 EcoCredits',
        win3_a_item5_score: '$50',
        win3_a_item6_title: '1500 EcoCredits',
        win3_a_item6_score: '$75',
        win3_a_item7_title: '2000 EcoCredits',
        win3_a_item7_score: '$100',
        win3_a_item8_title: '3000 EcoCredits',
        win3_a_item8_score: '$150',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Solar Angle Guide',
        win3_b_item1_score: '1800 EcoCredits',
        win3_b_item2_title: 'EcoFlow Camping Cup',
        win3_b_item2_score: '2600 EcoCredits',
        win3_b_item3_title: 'EcoFlow Emergency Light',
        win3_b_item3_score: '2600 EcoCredits',
        win3_b_item4_title: 'EcoFlow Camping Light',
        win3_b_item4_score: '4000 EcoCredits',
        win3_b_item5_title: 'EcoFlow RIVER Bag',
        win3_b_item5_score: '5000 EcoCredits',
        win3_b_item6_title: 'EcoFlow Camping Chair',
        win3_b_item6_score: '5000 EcoCredits',
        win3_b_item7_title: 'EcoFlow RIVER 2 Bag',
        win3_b_item7_score: '6000 EcoCredits',
        win3_b_item8_title: 'EcoFlow Camping Trolley',
        win3_b_item8_score: '7600 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Week',
        win4_sub_title: 'Highlights of the EcoCredits Appreciation Week event:',
        win4_item1: '7 days member-only discounts',
        win4_item2: 'Redeem coupons and gifts with EcoCredits',
        win4_item3: 'More discounts are waiting for you to explore! Never miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Win-Win Referral Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Enjoy 5% cash back on \nevery referred order.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',
    },
    jp: {
        LearnMore: '詳細',
        // 第1段落
        section_a_title: 'エコポイントプログラム',
        section_a_desc: 'メンバーになって、買い物をするたびにポイントと限定特典を獲得しましょう。',
        section_a_item1: 'ポイントの貯め方',
        section_a_item2: 'ポイントを特典と交換する',
        section_a_item3: 'ポイントをショッピングで使用',

        // 第2段落
        section_b_title: '',
        section_b_desc: '',

        //第3段落
        section_c_title: '友達紹介キャンペーン',
        section_c_desc: 'ご家族やお知り合いにエコフローを紹介してください。\n紹介された友だちには割引クーポンを、紹介者のあなたには特別なプレゼントをお届け！',

        // 底部
        footer_title: '会員登録して、ポイントや限定特典を獲得しましょう。',
        footer_btn: '新規会員登録',
        footer_btn2: 'ログインして友達を紹介する',
        footer_no_login_tips1: '',
        footer_no_login_tips2: 'ログインはこちら',

        // 子弹窗2-
        win2_title: 'ポイントの貯め方',
        win2_item1_title: '新規登録',
        win2_item1_score: ' 500ポイント',
        win2_item1_desc: 'アカウント登録して500ポイント獲得',
        win2_item2_title: 'メルマガ登録',
        win2_item2_score: '500ポイント',
        win2_item2_desc: 'ニュースレター購読すると500ポイント獲得',
        win2_item3_title: '公式サイトで買い物',
        win2_item3_score: '￥150＝1ポイント',
        win2_item3_desc: 'ご購入金額150円ごとに1ポイント獲得',
        win2_item4_title: '知り合いを招待する',
        win2_item4_score: '最大5,000ポイント/月',
        win2_item4_desc: '1人の登録につき100ポイント獲得',
        win2_item5_title: '保証',
        win2_item5_score: '1,000ポイント',
        win2_item5_desc: '製品登録すると1,000ポイント獲得',
        win2_item6_title: 'プロフィール作成',
        win2_item6_score: '1,000ポイント',
        win2_item6_desc: 'プロフィールを完成させると1,000ポイント獲得',

        // 子弹窗3
        win3_title: 'ポイントを特典と交換する',
        win3_a_title: 'ギフトカード',
        win3_a_item1_title: 'デジタルギフト1,500円分',
        win3_a_item1_score: '3,500ポイント',
        win3_a_item2_title: 'デジタルギフト3,000円分',
        win3_a_item2_score: '6,500ポイント',
        win3_a_item3_title: 'デジタルギフト5,000円分',
        win3_a_item3_score: '10,000ポイント',
        win3_b_title: 'アイテム',
        win3_b_item1_title: 'キャリーカート',
        win3_b_item1_score: '10,000ポイント',
        win3_b_item2_title: 'アウトドアチェア',
        win3_b_item2_score: '7,000 ポイント',
        win3_b_item3_title: '懐中電灯',
        win3_b_item3_score: '4,000 ポイント',
        win3_b_item4_title: '折りたたみ収納ボックス',
        win3_b_item4_score: '5,000 ポイント',

        // 子弹窗 D
        win4_title: '',
        win4_sub_title: '',
        win4_item1: '',
        win4_item2: '',
        win4_item3: '',

        // 子弹窗 E
        win5_title: '友達紹介',
        win5_item1: '友達が会員登録を完了すると、紹介者に100エコポイントをプレゼントします。',
        win5_item2: '友達紹介で4%のキャッシュバックを獲得！上限なし！',
        win5_item3: '紹介した友達の数が多い上位3名様には、EcoFlowの豪華賞品をプレゼントします。',
        win5_tips: '* 友達が商品購入をすると紹介者にその購入額の4%を現金で獲得！',

        // 子弹窗 F
        win6_title: 'ポイントをショッピングで使用',
        win6_sub_title: '',
        win6_desc: '貯めたポイントはチェックアウト時に\nクーポンと交換することができます。',
        win6_text: '例：',
        win6_item1: '1,500ポイント→1,500円OFFクーポン',
        win6_item2: '3,000ポイント→3,000円OFFクーポン',
    },
    za: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        // 第2段落
        section_b_title: 'EcoCredits Appreciation Week',
        section_b_desc: 'Happening every fourth week of the month.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Earn up to R 10,000 in cash per referral.',

        // 第4段落 za
        section_d_title: 'Join our Community for exclusive benefits',
        section_d_desc: '',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every R20 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: 'R100 OFF',
        win3_a_item2_title: '500 EcoCredits',
        win3_a_item2_score: 'R500 OFF',
        win3_a_item3_title: '1000 EcoCredits',
        win3_a_item3_score: 'R1000 OFF',
        win3_a_item4_title: '2000 EcoCredits',
        win3_a_item4_score: 'R2000 OFF',
        win3_a_item5_title: '2500 EcoCredits',
        win3_a_item5_score: 'R2500 OFF',
        win3_a_item6_title: '5000 EcoCredits',
        win3_a_item6_score: 'R5000 OFF',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Emergency Light',
        win3_b_item1_score: '3599 EcoCredits',
        win3_b_item2_title: 'EcoFlow Waterproof Picnic Blanket',
        win3_b_item2_score: '3999 EcoCredits',
        win3_b_item3_title: 'EcoFlow Camping Light',
        win3_b_item3_score: '4999 EcoCredits',
        win3_b_item4_title: 'EcoFlow Folding Wagon Cart',
        win3_b_item4_score: '9999 EcoCredits',
        win3_b_item5_title: 'EcoFlow RIVER 2 Bag',
        win3_b_item5_score: '3999 EcoCredits',
        win3_b_item6_title: 'EcoFlow DELTA 2 Bag',
        win3_b_item6_score: '4599 EcoCredits',
        win3_b_item7_title: 'EcoFlow DELTA Max Cover',
        win3_b_item7_score: '7999 EcoCredits',
        win3_b_item8_title: 'EcoFlow DELTA Pro Bag',
        win3_b_item8_score: '7999 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Appreciation Week',
        win4_sub_title: 'Highlights of the EcoCredits Appreciation Week event:',
        win4_item1: '7 days member-only discounts',
        win4_item2: 'Redeem coupons and gifts with EcoCredits',
        win4_item3: 'More discounts are waiting for you to explore! Never miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Refer a Friend & Get Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Earn 5% cash back on\nevery order you refer.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',

        // 子弹窗 G
        win7_title: 'Join our Community for exclusive benefits',
        win7_item1: 'Get early access to new products',
        win7_item2: 'Join exciting offline events',
        win7_item3: 'Engage in community discussions',
        win7_btn: 'Join Our Community',
    },
    kr: {
        LearnMore: '더 알아보기',
        // 第1段落
        section_a_title: '에코포인트 프로그램',
        section_a_desc: '멤버 가입 후  무료 배송 혜택과 에코포인트 프로그램을 통한 다양한 쿠폰 및 현금 할인을 받아보세요.',
        section_a_item1: '에코포인트 적립 방법',
        section_a_item2: '에코포인트 혜택',

        //第2段落
        section_b_title: '에코포인트 감사제',
        section_b_desc: '매 분기마다 진행!',

        // 第3段落
        section_c_title: '친구 추천하기',
        section_c_desc: '추천하는  1인마다  2% 할인 쿠폰을, 최대 100만원의 한도의 캐시백이 지원됩니다.',

        // 底部
        footer_title: '더 많은 혜택을 확인하세요!',
        footer_btn: '가입하기',
        footer_btn2: '지금 추천하세요',
        footer_no_login_tips1: '이미 멤버이신가요? ',
        footer_no_login_tips2: '로그인하기',

        // 子弹窗2-
        win2_title: '에코포인트 적립 방법',
        win2_item1_title: '회원가입 후 구독',
        win2_item1_score: '1000 에코포인트 증정',
        win2_item1_desc: '회원 가입 후 에코플로우 뉴스레터 구독',
        win2_item2_title: '스토어',
        win2_item2_score: '70,000원 구매시마다 1000 에코포인트 증정',
        win2_item2_desc: '매번 제품 구매 시',
        win2_item3_title: '친구 추천',
        win2_item3_score: '최대 3000 에코포인트 증정',
        win2_item3_desc: '추천인을 통해 가입하는 신규 멤버마다 100 에코포인트 증정',
        win2_item4_title: '제품 등록',
        win2_item4_score: '1000 에코포인트 증정',
        win2_item4_desc: '제품 보증을 위한 제품 등록시마다 1000 에코포인트 증정',
        win2_item5_title: '프로필 작성',
        win2_item5_score: '1000 에코포인트 증정',
        win2_item5_desc: '상세 프로필 작성 후 제출하면 1000 에코포인트 증정',

        // 子弹窗3
        win3_title: '에코포인트 혜택',
        win3_a_title: '쿠폰',
        win3_a_item1_title: '200 에코포인트',
        win3_a_item1_score: '₩14,000',
        win3_a_item2_title: '300 에코포인트',
        win3_a_item2_score: '₩21,000',
        win3_a_item3_title: '400 에코포인트',
        win3_a_item3_score: '₩28,000',
        win3_a_item4_title: '500 에코포인트',
        win3_a_item4_score: '₩35,000',
        win3_a_item5_title: '1000 에코포인트',
        win3_a_item5_score: '₩70,000',
        win3_a_item6_title: '1500 에코포인트',
        win3_a_item6_score: '₩105,000',
        win3_a_item7_title: '2000 에코포인트',
        win3_a_item7_score: '₩140,000',
        win3_b_title: '기프트',
        win3_b_item1_title: '블랙 티셔츠',
        win3_b_item1_score: '4900 에코포인트',
        win3_b_item2_title: '델타2 전용가방',
        win3_b_item2_score: '6000 에코포인트',
        win3_b_item3_title: '에코플로우 폴딩 박스',
        win3_b_item3_score: '5900 에코포인트',
        win3_b_item4_title: '에코플로우 피크닉 매트',
        win3_b_item4_score: '5900 에코포인트',
        win3_b_item5_title: '에코플로우 캠핑 의자',
        win3_b_item5_score: '7900 에코포인트',
        win3_b_item6_title: '에코플로우 캠핑 카트',
        win3_b_item6_score: '8900 에코포인트',

        // 子弹窗 D
        win4_title: '에코포인트 감사제',
        win4_sub_title: '에코포인트 감사제 이벤트 하이라이트:',
        win4_item1: '7일간 멤버 전용 할인 혜택 제공',
        win4_item2: '에코포인트를 통한 할인 및 기프트 교환',
        win4_item3: '이외에도 다양한 혜택이 기다리고 있습니다.\n특별한 이번 기회를 놓지지 마세요!',

        // 子弹窗 E
        win5_title: 'Win-Win 추천 혜택',
        win5_item1: '추천인을 통해 가입하는 신규 멤버마다\n100 에코포인트 증정',
        win5_item2: '추천을 통해 진행된 주문액의 5%\n캐시백 증정',
        win5_item3: '가장 추천을 많이 받은 3인에게는\n에코플로우의 특별한 보상도 주어집니다!',
    },
    eu: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        //第2段落
        section_b_title: 'EcoCredits Appreciation Week',
        section_b_desc: 'Happening every fourth week of the month.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Give a 5% off coupon, and earn up to €500 cash per referral.',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every €1 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '€5',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '€10',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '€25',
        win3_a_item4_title: '1000 EcoCredits',
        win3_a_item4_score: '€50',
        win3_a_item5_title: '2000 EcoCredits',
        win3_a_item5_score: '€100',
        win3_a_item6_title: '4000 EcoCredits',
        win3_a_item6_score: '€200',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Badge',
        win3_b_item1_score: '1199 EcoCredits',
        win3_b_item2_title: 'EcoFlow Camping Mat',
        win3_b_item2_score: '5499 EcoCredits',
        win3_b_item3_title: 'EcoFlow Camping Light',
        win3_b_item3_score: '4599 EcoCredits',
        win3_b_item4_title: 'EcoFlow Smart Plug',
        win3_b_item4_score: '5999 EcoCredits',
        win3_b_item5_title: 'EcoFlow Flatbed Trolley',
        win3_b_item5_score: '7449 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Week',
        win4_sub_title: 'Highlights of the EcoCredits Appreciation Week event:',
        win4_item1: '7 days member-only discounts',
        win4_item2: 'Redeem coupons and gifts with EcoCredits',
        win4_item3: 'More discounts are waiting for you to explore! Never miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Win-Win Referral Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Enjoy 5% cash back on \nevery referred order.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',
    },
    de: {
        LearnMore: 'Mehr erfahren',
        // 第1段落
        section_a_title: 'EcoCredits Prämienprogramm',
        section_a_desc: 'Als Mitglied profitieren Sie von kostenlosem Versand und sammeln EcoCredits für Rabattgutscheine und Barabzüge.',
        section_a_item1: 'So sammeln Sie EcoCredits',
        section_a_item2: 'Prämien erhalten',

        //第2段落
        section_b_title: 'EcoCredits Aktionswoche',
        section_b_desc: 'Sie findet jede vierte Woche im Monat statt.',

        // 第3段落
        section_c_title: 'Einen Freund werben',
        section_c_desc: 'Verschenken Sie einen 5 % Rabattcoupon an Ihre Freunde und Sie erhalten bis zu 500 € pro Empfehlung.',

        // 底部
        footer_title: 'Lassen Sie uns gemeinsam die exklusiven Prämien entdecken!',
        footer_btn: 'Jetzt teilnehmen',
        footer_btn2: 'Jetzt weiterempfehlen',
        footer_no_login_tips1: 'Haben Sie bereits ein Konto? ',
        footer_no_login_tips2: 'Anmelden',

        // 子弹窗2-
        win2_title: 'So sammeln Sie EcoCredits',
        win2_item1_title: 'Registrieren und abonnieren',
        win2_item1_score: 'Sie erhalten 1 000 EcoCredits',
        win2_item1_desc: 'Machen Sie mit und abonnieren Sie den EcoFlow Newsletter.',
        win2_item2_title: 'Kaufen Sie ein',
        win2_item2_score: '1 EcoCredit für jeden ausgegebenen Euro',
        win2_item2_desc: 'Sie punkten bei jeder Bestellung.',
        win2_item3_title: 'Empfehlen Sie einen Freund',
        win2_item3_score: 'Bis zu 3 000 EcoCredits',
        win2_item3_desc: 'Für jede erfolgreiche Freundschaftswerbung erhalten Sie 100 EcoCredits.',
        win2_item4_title: 'Garantie',
        win2_item4_score: '1 000 EcoCredits',
        win2_item4_desc: 'Registrieren Sie Ihre Produktinformationen für die Garantie und Sie erhalten 1 000 Punkte für jedes registrierte Produkt.',
        win2_item5_title: 'Profil',
        win2_item5_score: '1 000 EcoCredits',
        win2_item5_desc: 'Ergänzen Sie Ihre Angaben, z.B. Ihren Geburtstag.',

        // 子弹窗3
        win3_title: 'Prämien erhalten',
        win3_a_title: 'Rabattgutscheine',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '5 €',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '10 €',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '25 €',
        win3_a_item4_title: '1 000 EcoCredits',
        win3_a_item4_score: '50 €',
        win3_a_item5_title: '2 000 EcoCredits',
        win3_a_item5_score: '100 €',
        win3_a_item6_title: '4 000 EcoCredits',
        win3_a_item6_score: '200 €',
        win3_b_title: 'Geschenke',
        win3_b_item1_title: 'EcoFlow Badge',
        win3_b_item1_score: '1 199 EcoCredits',
        win3_b_item2_title: 'EcoFlow Picknickdecke',
        win3_b_item2_score: '5 499 EcoCredits',
        win3_b_item3_title: 'EcoFlow Campinglampe',
        win3_b_item3_score: '4 599 EcoCredits',
        win3_b_item4_title: 'EcoFlow Smart Plug',
        win3_b_item4_score: '5 999 EcoCredits',
        win3_b_item5_title: 'EcoFlow Bollerwagen',
        win3_b_item5_score: '7 449 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Aktionswoche',
        win4_sub_title: 'Höhepunkte der EcoCredits Aktionswoche',
        win4_item1: '7 Tage lang exklusive Mitgliederrabatte',
        win4_item2: 'Gutscheine und Geschenke mit EcoCredits einlösen',
        win4_item3: 'Weitere Rabatte warten darauf, von Ihnen entdeckt zu werden! Verpassen Sie nie wieder einen Sonderverkauf oder eine Werbeaktion!',

        // 子弹窗 E
        win5_title: 'Win-Win-Empfehlungsprämien',
        win5_item1: `Sie erhalten 100 EcoCredits für jede erfolgreiche Empfehlung.`,
        win5_item2: 'Genießen Sie 5 % Cashback für jede empfohlene Bestellung.',
        win5_item3: 'Die drei erfolgreichsten Freundschaftswerber erhalten EcoFlow Hauptpreise.',
    },
    fr: {
        LearnMore: 'En savoir plus',
        // 第1段落
        section_a_title: 'Programme EcoFlow EcoCredits',
        section_a_desc:
            'Devenez membre et bénéficiez de la livraison gratuite, gagnez des EcoCredits à échanger contre des coupons de réduction et des remises exclusives.',
        section_a_item1: 'Comment gagner des EcoCredits ?',
        section_a_item2: 'Obtenez des récompenses',

        //第2段落
        section_b_title: 'Semaine des EcoCredits',
        section_b_desc: 'Les ventes flash ont lieu la dernière semaine de chaque mois.',

        // 第3段落
        section_c_title: 'Parrainez un ami',
        section_c_desc: `Offrez un coupon de réduction de 5 %, gagnez jusqu'à 500 € par parrainage.`,

        // 底部
        footer_title: `Bénéficiez d'avantages exclusifs réservés aux membres !`,
        footer_btn: 'S’inscrire',
        footer_btn2: 'Parrainer',
        footer_no_login_tips1: 'Vous avez déjà un compte ? ',
        footer_no_login_tips2: 'Se connecter',

        // 子弹窗2-
        win2_title: 'Comment gagner des EcoCredits ?',
        win2_item1_title: `S'inscrire et s'abonner`,
        win2_item1_score: '1 000 EcoCredits',
        win2_item1_desc: 'Rejoignez notre communauté et abonnez-vous à la newsletter EcoFlow.',
        win2_item2_title: 'Passer commande',
        win2_item2_score: '1 EcoCredit pour chaque euro dépensé',
        win2_item2_desc: 'Gagnez des points à chaque achat.',
        win2_item3_title: 'Parrainer un ami',
        win2_item3_score: `Jusqu'à 3 000 EcoCredits`,
        win2_item3_desc: `Gagnez 100 EcoCredits pour chaque inscription parrainée.`,
        win2_item4_title: 'Enregistrer une garantie',
        win2_item4_score: '1 000 EcoCredits',
        win2_item4_desc: 'Renseignez les informations de votre produit et obtenez 1 000 points.',
        win2_item5_title: 'Compléter son profil',
        win2_item5_score: '1 000 EcoCredits',
        win2_item5_desc: 'Renseignez vos informations personnelles, telles que votre date de naissance.',

        // 子弹窗3
        win3_title: 'Obtenez des récompenses',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '5 €',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '10 €',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '25 €',
        win3_a_item4_title: '1 000 EcoCredits',
        win3_a_item4_score: '50 €',
        win3_a_item5_title: '2 000 EcoCredits',
        win3_a_item5_score: '100 €',
        win3_a_item6_title: '4 000 EcoCredits',
        win3_a_item6_score: '200 €',
        win3_b_title: 'Cadeaux',
        win3_b_item1_title: 'Badge EcoFlow',
        win3_b_item1_score: '1 199 EcoCredits',
        win3_b_item2_title: 'Tapis de camping EcoFlow',
        win3_b_item2_score: '5 499 EcoCredits',
        win3_b_item3_title: 'Lampe de camping EcoFlow',
        win3_b_item3_score: '4 599 EcoCredits',
        win3_b_item4_title: 'Prise intelligente',
        win3_b_item4_score: '5 999 EcoCredits',
        win3_b_item5_title: 'Chariot à plateau EcoFlow',
        win3_b_item5_score: '7 449 EcoCredits',

        // 子弹窗 D
        win4_title: 'Semaine des EcoCredits',
        win4_sub_title: 'La Semaine des EcoCredits, c’est :',
        win4_item1: '7 jours de réductions exclusives',
        win4_item2: 'Échangez des coupons et des cadeaux avec des EcoCredits',
        win4_item3: 'Remises exceptionnelles à ne pas manquer !',

        // 子弹窗 E
        win5_title: 'Gagnant-gagnant pour tous',
        win5_item1: `Vous obtiendrez 100 EcoCredits pour chaque inscription parrainée.`,
        win5_item2: 'Profitez de 5 % de cashback sur chaque commande parrainée.',
        win5_item3: 'Les trois meilleurs parrains gagnent de grands prix EcoFlow.',
    },
    es: {
        LearnMore: 'Más información',
        // 第1段落
        section_a_title: 'Programa de EcoCredits',
        section_a_desc: 'Conviértete en miembro y ahórrate los gastos de envío, y gana EcoCredits para conseguir descuentos y deducciones en efectivo.',
        section_a_item1: 'Cómo conseguir EcoCredits',
        section_a_item2: 'Obtén recompensas',

        //第2段落
        section_b_title: 'Semana de agradecimiento de EcoCredits',
        section_b_desc: 'Tiene lugar la cuarta semana de cada mes.',

        // 第3段落
        section_c_title: 'Recomienda a un amigo',
        section_c_desc: 'Regala un cupón de descuento del 5 % y gana hasta 500 € en efectivo por referencia.',

        // 底部
        footer_title: '¡Exploremos juntos las exclusivas recompensas!',
        footer_btn: 'Únete ahora',
        footer_btn2: 'Recomendar ahora',
        footer_no_login_tips1: '¿Ya tienes una cuenta? ',
        footer_no_login_tips2: 'Iniciar sesión',

        // 子弹窗2-
        win2_title: 'Cómo conseguir EcoCredits',
        win2_item1_title: 'Regístrate y suscríbete',
        win2_item1_score: 'Obtén 1000 EcoCredits',
        win2_item1_desc: 'Únete a nosotros y suscríbete al boletín de EcoFlow.',
        win2_item2_title: 'Compra',
        win2_item2_score: 'Recibe 1 EcoCredit por cada 1 € que gastes',
        win2_item2_desc: 'Gana cada vez que hagas un pedido. ',
        win2_item3_title: 'Refiere a un amigo',
        win2_item3_score: 'Obtén hasta 3000 EcoCredits',
        win2_item3_desc: 'Obtendrás 100 EcoCredits por cada referencia de registro exitoso.',
        win2_item4_title: 'Registro de Garantía',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Registra la información de tu producto para la garantía y podrás obtener 1000 puntos por cada producto registrado.',
        win2_item5_title: 'Completa tu Perfil',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Agrega tu información, como la fecha de tu cumpleaños.',

        // 子弹窗3
        win3_title: 'Obtén recompensas',
        win3_a_title: 'Cupones',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '5 €',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '10 €',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '25 €',
        win3_a_item4_title: '1000 EcoCredits',
        win3_a_item4_score: '50 €',
        win3_a_item5_title: '2000 EcoCredits',
        win3_a_item5_score: '100 €',
        win3_a_item6_title: '4000 EcoCredits',
        win3_a_item6_score: '200 €',
        win3_b_title: 'Regalos',
        win3_b_item1_title: 'Broche EcoFlow',
        win3_b_item1_score: '1199 EcoCredits',
        win3_b_item2_title: 'Colchoneta para camping EcoFlow',
        win3_b_item2_score: '5499 EcoCredits',
        win3_b_item3_title: 'Lámpara para camping EcoFlow',
        win3_b_item3_score: '4599 EcoCredits',
        win3_b_item4_title: 'Enchufe inteligente EcoFlow',
        win3_b_item4_score: '5999 EcoCredits',
        win3_b_item5_title: 'Carrito plano EcoFlow',
        win3_b_item5_score: '7449 EcoCredits',

        // 子弹窗 D
        win4_title: 'Semana de EcoCredits',
        win4_sub_title: 'Puntos destacados del evento de la Semana de Agradecimiento de EcoCredits:',
        win4_item1: '7 días de descuentos exclusivos para miembros',
        win4_item2: 'Canjea cupones y regalos con EcoCredits',
        win4_item3: '¡Te esperan más descuentos exclusivos! ¡No vuelvas a perderte una oferta o promoción!',

        // 子弹窗 E
        win5_title: 'Recompensas por recomendaciones',
        win5_item1: `Obtendrás 100 EcoCredits por cada amigo recomendado que se registre.`,
        win5_item2: 'Disfruta de un reembolso en efectivo del 5 % en cada pedido recomendado.',
        win5_item3: 'Las 3 personas que más recomienden ganarán grandes premios.',
    },
    it: {
        LearnMore: 'Scopri di più',
        // 第1段落
        section_a_title: 'Programma EcoCredits',
        section_a_desc: 'Diventa membro per ottenere la spedizione gratuita, guadagnare EcoCredits e ricevere coupon e detrazioni in denaro.',
        section_a_item1: 'Come guadagnare EcoCredits',
        section_a_item2: 'Ricevi premi',

        //第2段落
        section_b_title: 'Settimana di Apprezzamento degli EcoCredits',
        section_b_desc: 'Si verifica ogni quarta settimana del mese.',

        // 第3段落
        section_c_title: 'Invita un amico',
        section_c_desc: 'Regala un buono sconto del 5%, ricevi fino a 500 € in denaro per ogni referral.',

        // 底部
        footer_title: `Esploriamo insieme gli esclusivi premi!`,
        footer_btn: 'Unisciti ora',
        footer_btn2: 'Invita ora',
        footer_no_login_tips1: 'Hai già un account? ',
        footer_no_login_tips2: 'Accedi',

        // 子弹窗2-
        win2_title: 'Come guadagnare EcoCredits',
        win2_item1_title: 'Registrati e iscriviti',
        win2_item1_score: 'Ottieni 1.000 EcoCredits',
        win2_item1_desc: 'Unisciti a noi e iscriviti alla newsletter EcoFlow.',
        win2_item2_title: 'Fai acquisti',
        win2_item2_score: '1 EcoCredit per ogni 1 € speso',
        win2_item2_desc: 'Guadagna punti con ogni ordine.',
        win2_item3_title: 'Invita un amico',
        win2_item3_score: 'Fino a 3.000 EcoCredits',
        win2_item3_desc: `Riceverai 100 EcoCredits per ogni referral di iscrizione andata a buon fine.`,
        win2_item4_title: 'Garanzia',
        win2_item4_score: '1.000 EcoCredits',
        win2_item4_desc: 'Registra le informazioni del tuo prodotto per la garanzia: puoi ricevere 1.000 punti per ogni prodotto registrato.',
        win2_item5_title: 'Profilo',
        win2_item5_score: '1.000 EcoCredits',
        win2_item5_desc: 'Aggiunta dei tuoi dettagli, come la data di nascita.',

        // 子弹窗3
        win3_title: 'Ricevi premi',
        win3_a_title: 'Buoni',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '5 €',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '10 €',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '25 €',
        win3_a_item4_title: '1.000 EcoCredits',
        win3_a_item4_score: '50 €',
        win3_a_item5_title: '2.000 EcoCredits',
        win3_a_item5_score: '100 €',
        win3_a_item6_title: '4.000 EcoCredits',
        win3_a_item6_score: '200 €',
        win3_b_title: 'Regali',
        win3_b_item1_title: 'Badge EcoFlow',
        win3_b_item1_score: '1.199 EcoCredits',
        win3_b_item2_title: 'Tappetino da campeggio EcoFlow',
        win3_b_item2_score: '5.499 EcoCredits',
        win3_b_item3_title: 'Lampada da campeggio EcoFlow',
        win3_b_item3_score: '4.599 EcoCredits',
        win3_b_item4_title: 'EcoFlow Smart Plug',
        win3_b_item4_score: '5.999 EcoCredits',
        win3_b_item5_title: 'Trolley a fondo piano EcoFlow',
        win3_b_item5_score: '7.449 EcoCredits',

        // 子弹窗 D
        win4_title: 'Settimana degli EcoCredits',
        win4_sub_title: 'Messa in evidenza dell’evento Settimana di Apprezzamento degli EcoCredits:',
        win4_item1: '7 giorni di sconti riservati ai membri',
        win4_item2: 'Riscatta buoni e premi con EcoCredits',
        win4_item3: 'Altri sconti tutti da scoprire ti aspettano! Non perderti mai più un’offerta o una promozione!',

        // 子弹窗 E
        win5_title: 'Premi referral win-win',
        win5_item1: `Riceverai 100 EcoCredits per ogni referral di iscrizione andata a buon fine.`,
        win5_item2: 'Approfitta del 5% di cash back su ogni ordine effettuato a seguito di un invito tramite referral.',
        win5_item3: 'I primi 3 referrer guadagnano grandi premi EcoFlow.',
    },
    uk: {
        LearnMore: 'Learn More',
        // 第1段落
        section_a_title: 'EcoCredits Program',
        section_a_desc: 'Become a member and get free shipping, earn EcoCredits for discount vouchers and cash deductions.',
        section_a_item1: 'How to Earn EcoCredits',
        section_a_item2: 'Get Rewards',

        //第2段落
        section_b_title: 'EcoCredits Appreciation Week',
        section_b_desc: 'Happening every fourth week of the month.',

        // 第3段落
        section_c_title: 'Refer a Friend & Get Rewards',
        section_c_desc: 'Give a 5% off coupon, and earn up to £500 cash per referral.',

        // 底部
        footer_title: `Let's explore the exclusive rewards together!`,
        footer_btn: 'Join Now',
        footer_btn2: 'Refer Now',
        footer_no_login_tips1: 'Already have an account? ',
        footer_no_login_tips2: 'Log in',

        // 子弹窗2-
        win2_title: 'How to Earn EcoCredits',
        win2_item1_title: 'Register and Subscribe',
        win2_item1_score: 'Get 1000 EcoCredits',
        win2_item1_desc: 'Join us and subscribe to the EcoFlow newsletter.',
        win2_item2_title: 'Shop',
        win2_item2_score: '1 EcoCredit for every £1 spent',
        win2_item2_desc: 'Earn every time you order.',
        win2_item3_title: 'Refer a Friend',
        win2_item3_score: 'Up to 3000 EcoCredits',
        win2_item3_desc: `You'll get 100 EcoCredits for every successful sign-up referral.`,
        win2_item4_title: 'Warranty',
        win2_item4_score: '1000 EcoCredits',
        win2_item4_desc: 'Register your product information for warranty, and you can get 1000 points for each registered product.',
        win2_item5_title: 'Profile',
        win2_item5_score: '1000 EcoCredits',
        win2_item5_desc: 'Adding your details, like your birthday.',

        // 子弹窗3
        win3_title: 'Get Rewards',
        win3_a_title: 'Coupons',
        win3_a_item1_title: '100 EcoCredits',
        win3_a_item1_score: '£ 5',
        win3_a_item2_title: '200 EcoCredits',
        win3_a_item2_score: '£ 10',
        win3_a_item3_title: '500 EcoCredits',
        win3_a_item3_score: '£ 25',
        win3_a_item4_title: '1000 EcoCredits',
        win3_a_item4_score: '£ 50',
        win3_a_item5_title: '2000 EcoCredits',
        win3_a_item5_score: '£ 100',
        win3_a_item6_title: '4000 EcoCredits',
        win3_a_item6_score: '£ 200',
        win3_b_title: 'Gifts',
        win3_b_item1_title: 'EcoFlow Badge',
        win3_b_item1_score: '1199 EcoCredits',
        win3_b_item2_title: 'EcoFlow Camping Mat',
        win3_b_item2_score: '5499 EcoCredits',
        win3_b_item3_title: 'EcoFlow Camping Light',
        win3_b_item3_score: '4599 EcoCredits',
        win3_b_item4_title: 'EcoFlow Smart Plug',
        win3_b_item4_score: '5999 EcoCredits',
        win3_b_item5_title: 'EcoFlow Flatbed Trolley',
        win3_b_item5_score: '7449 EcoCredits',

        // 子弹窗 D
        win4_title: 'EcoCredits Week',
        win4_sub_title: 'Highlights of the EcoCredits Appreciation Week event:',
        win4_item1: '7 days member-only discounts',
        win4_item2: 'Redeem coupons and gifts with EcoCredits',
        win4_item3: 'More discounts are waiting for you to explore! Never miss another sale or promo!',

        // 子弹窗 E
        win5_title: 'Win-Win Referral Rewards',
        win5_item1: `You'll get 100 EcoCredits \nfor every successful sign-up referral.`,
        win5_item2: 'Enjoy 5% cash back on \nevery referred order.',
        win5_item3: 'Top 3 referrers earn EcoFlow \ngrand prizes.',
    },
};
