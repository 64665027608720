import { langText } from '../langText';
import { IconPrev } from '../icon';
import getLocal from '@/app/utils/getLocal';

export default function SectionB({ switchHandle }: any) {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];

    return (
        <div className="EcoCreditsWin-Section">
            <div className="EcoCreditsWin-title">{TextA.section_b_title}</div>
            <div className="EcoCreditsWin-desc">{TextA.section_b_desc}</div>
            <div className="EcoCreditsWin-link" onClick={() => switchHandle('d')}>
                {TextA.LearnMore}
                <IconPrev />
            </div>
        </div>
    );
}
