import dynamic from 'next/dynamic';

export const Icon = dynamic(() => import('./Icon'));
export const IconBack = dynamic(() => import('./IconBack'));
export const IconClose = dynamic(() => import('./IconClose'));
export const IconPrev = dynamic(() => import('./IconPrev'));
// ICON A EcoCredits Program
export const IconaEarnEC = dynamic(() => import('./IconaEarnEC'));
export const IconaGetRwd = dynamic(() => import('./IconaGetRwd'));
// JP
export const IconaCart = dynamic(() => import('./IconaCart'));
export const IconaGift = dynamic(() => import('./IconaGift'));

// ICON B How to Earn EcoCredits
export const IconbRaS = dynamic(() => import('./IconbRaS'));
export const IconbShop = dynamic(() => import('./IconbShop'));
export const IconbRaF = dynamic(() => import('./IconbRaF'));
export const IconbWarranty = dynamic(() => import('./IconbWarranty'));
export const IconbProfile = dynamic(() => import('./IconbProfile'));
// JP
export const IconbIDCard = dynamic(() => import('./IconbIDCard'));
export const IconbEmail = dynamic(() => import('./IconbEmail'));
export const IconbCart = dynamic(() => import('./IconbCart'));
export const IconbTwoHm = dynamic(() => import('./IconbTwoHm'));
export const IconbPaper = dynamic(() => import('./IconbPaper'));

//  ICON C Get Rewards
export const IconcCoupons = dynamic(() => import('./IconcCoupons'));
export const IconcCaps = dynamic(() => import('./IconcCaps'));
export const IconcCpChair = dynamic(() => import('./IconcCpChair'));
export const IconcCpLight = dynamic(() => import('./IconcCpLight'));
export const IconcDPBag = dynamic(() => import('./IconcDPBag'));
export const IconcWPBlanket = dynamic(() => import('./IconcWPBlanket'));
// CA
export const IconcKeyChain = dynamic(() => import('./IconcKeyChain'));
export const IconcCpLight2 = dynamic(() => import('./IconcCpLight2'));
// JP
export const IconcCart = dynamic(() => import('./IconcCart'));
export const IconcBox = dynamic(() => import('./IconcBox'));
export const IconcGiftCard = dynamic(() => import('./IconcGiftCard'));
// AU
export const IconcSAGuide = dynamic(() => import('./IconcSAGuide'));
export const IconcCpCup = dynamic(() => import('./IconcCpCup'));
export const IconcRiverBag = dynamic(() => import('./IconcRiverBag'));
// ZA
export const IconcDMCover = dynamic(() => import('./IconcDMCover'));
export const IconcCouponsBig = dynamic(() => import('./IconcCouponsBig'));
// KR
export const IconcTshirt = dynamic(() => import('./IconcTshirt'));
// EU
export const IconcBadge = dynamic(() => import('./IconcBadge'));
export const IconcSmartPlug = dynamic(() => import('./IconcSmartPlug'));

//  ICON D EcoCredits Monthly Madness
export const Icond1 = dynamic(() => import('./Icond1'));
export const Icond2 = dynamic(() => import('./Icond2'));
export const Icond3 = dynamic(() => import('./Icond3'));

// ICON E Refer a Friend & Get Rewards
export const Icone1 = dynamic(() => import('./Icone1'));
export const Icone2 = dynamic(() => import('./Icone2'));
export const Icone3 = dynamic(() => import('./Icone3'));

// ICON G ZA Join our Community for exclusive benefits
export const IconfCar = dynamic(() => import('./IconfCar'));
export const IconfTent = dynamic(() => import('./IconfTent'));
export const IconfChat = dynamic(() => import('./IconfChat'));
