import {
    IconcCaps,
    IconcCoupons,
    IconcCouponsBig,
    IconcCpChair,
    IconcCpLight,
    IconcDPBag,
    IconcWPBlanket,
    IconcKeyChain,
    IconcCpLight2,
    IconcSAGuide,
    IconcCpCup,
    IconcRiverBag,
    IconcDMCover,
    IconcCart,
    IconcBox,
    IconcGiftCard,
    IconcTshirt,
    IconcBadge,
    IconcSmartPlug,
} from '../icon';
import styles from '../index.module.scss';
import { langText } from '../langText';
import getLocal from '@/app/utils/getLocal';

// 主弹窗
export default function WinC() {
    const { locale } = getLocal();
    const TextA = langText[locale] || langText['us'];
    const CouponsMap: any = {
        us: [
            {
                title: TextA.win3_a_item1_title,
                score: TextA.win3_a_item1_score,
            },
            {
                title: TextA.win3_a_item2_title,
                score: TextA.win3_a_item2_score,
            },
            {
                title: TextA.win3_a_item3_title,
                score: TextA.win3_a_item3_score,
            },

            {
                title: TextA.win3_a_item4_title,
                score: TextA.win3_a_item4_score,
            },
            {
                title: TextA.win3_a_item5_title,
                score: TextA.win3_a_item5_score,
            },
            {
                title: TextA.win3_a_item6_title,
                score: TextA.win3_a_item6_score,
            },
        ],
        au: [
            {
                title: TextA.win3_a_item1_title,
                score: TextA.win3_a_item1_score,
            },
            {
                title: TextA.win3_a_item2_title,
                score: TextA.win3_a_item2_score,
            },
            {
                title: TextA.win3_a_item3_title,
                score: TextA.win3_a_item3_score,
            },

            {
                title: TextA.win3_a_item4_title,
                score: TextA.win3_a_item4_score,
            },
            {
                title: TextA.win3_a_item5_title,
                score: TextA.win3_a_item5_score,
            },
            {
                title: TextA.win3_a_item6_title,
                score: TextA.win3_a_item6_score,
            },
            {
                title: TextA.win3_a_item7_title,
                score: TextA.win3_a_item7_score,
            },
            {
                title: TextA.win3_a_item8_title,
                score: TextA.win3_a_item8_score,
            },
        ],
        jp: [
            {
                icon: <IconcGiftCard />,
                title: TextA.win3_a_item1_title,
                score: TextA.win3_a_item1_score,
            },
            { icon: <IconcGiftCard />, title: TextA.win3_a_item2_title, score: TextA.win3_a_item2_score },
            { icon: <IconcGiftCard />, title: TextA.win3_a_item3_title, score: TextA.win3_a_item3_score },
        ],
        kr: [
            {
                title: TextA.win3_a_item1_title,
                score: TextA.win3_a_item1_score,
            },
            {
                title: TextA.win3_a_item2_title,
                score: TextA.win3_a_item2_score,
            },
            {
                title: TextA.win3_a_item3_title,
                score: TextA.win3_a_item3_score,
            },

            {
                title: TextA.win3_a_item4_title,
                score: TextA.win3_a_item4_score,
            },
            {
                title: TextA.win3_a_item5_title,
                score: TextA.win3_a_item5_score,
            },
            {
                title: TextA.win3_a_item6_title,
                score: TextA.win3_a_item6_score,
            },
            {
                title: TextA.win3_a_item7_title,
                score: TextA.win3_a_item7_score,
            },
        ],
    };
    const listCoupons = CouponsMap[locale] || CouponsMap['us'];
    const iconMap: any = {
        us: [<IconcCaps key="1" />, <IconcWPBlanket key="2" />, <IconcCpLight2 key="3" />, <IconcDPBag key="4" />, <IconcCpChair key="5" />],
        ca: [<IconcKeyChain key="1" />, <IconcWPBlanket key="2" />, <IconcCpLight2 key="3" />, <IconcDPBag key="4" />, <IconcCpChair key="5" />],
        au: [
            <IconcSAGuide key="1" />,
            <IconcCpCup key="2" />,
            <IconcCpLight key="3" />,
            <IconcCpLight2 key="4" />,
            <IconcRiverBag key="5" />,
            <IconcCpChair key="6" />,
            <IconcDMCover key="7" />,
            <IconcCart key="8" />,
        ],
        jp: [<IconcCart key="1" />, <IconcCpChair key="2" />, <IconcCpLight2 key="3" />, <IconcBox key="4" />],
        za: [
            <IconcCpLight2 key="1" />,
            <IconcSAGuide key="2" />,
            <IconcCpLight key="3" />,
            <IconcCart key="4" />,
            <IconcRiverBag key="5" />,
            <IconcRiverBag key="6" />,
            <IconcDMCover key="7" />,
            <IconcRiverBag key="8" />,
        ],
        kr: [
            <IconcTshirt key="1" />,
            <IconcDMCover key="2" />,
            <IconcBox key="3" />,
            <IconcWPBlanket key="4" />,
            <IconcCpChair key="5" />,
            <IconcCart key="6" />,
        ],
        eu: [<IconcBadge key="1" />, <IconcWPBlanket key="2" />, <IconcCpLight2 key="3" />, <IconcSmartPlug key="4" />, <IconcCart key="5" />],
    };
    const icons = iconMap[locale] || iconMap['eu'];
    const GiftMap: any = {
        us: [
            {
                icon: icons[0],
                title: TextA.win3_b_item1_title,
                score: TextA.win3_b_item1_score,
            },
            {
                icon: icons[1],
                title: TextA.win3_b_item2_title,
                score: TextA.win3_b_item2_score,
            },
            {
                icon: icons[2],
                title: TextA.win3_b_item3_title,
                score: TextA.win3_b_item3_score,
            },
            {
                icon: icons[3],
                title: TextA.win3_b_item4_title,
                score: TextA.win3_b_item4_score,
            },
            {
                icon: icons[4],
                title: TextA.win3_b_item5_title,
                score: TextA.win3_b_item5_score,
            },
        ],
        au: [
            {
                icon: icons[0],
                title: TextA.win3_b_item1_title,
                score: TextA.win3_b_item1_score,
            },
            {
                icon: icons[1],
                title: TextA.win3_b_item2_title,
                score: TextA.win3_b_item2_score,
            },
            {
                icon: icons[2],
                title: TextA.win3_b_item3_title,
                score: TextA.win3_b_item3_score,
            },
            {
                icon: icons[3],
                title: TextA.win3_b_item4_title,
                score: TextA.win3_b_item4_score,
            },
            {
                icon: icons[4],
                title: TextA.win3_b_item5_title,
                score: TextA.win3_b_item5_score,
            },
            {
                icon: icons[5],
                title: TextA.win3_b_item6_title,
                score: TextA.win3_b_item6_score,
            },
            {
                icon: icons[6],
                title: TextA.win3_b_item7_title,
                score: TextA.win3_b_item7_score,
            },
            {
                icon: icons[7],
                title: TextA.win3_b_item8_title,
                score: TextA.win3_b_item8_score,
            },
        ],
        jp: [
            {
                icon: icons[0],
                title: TextA.win3_b_item1_title,
                score: TextA.win3_b_item1_score,
            },
            {
                icon: icons[1],
                title: TextA.win3_b_item2_title,
                score: TextA.win3_b_item2_score,
            },
            {
                icon: icons[2],
                title: TextA.win3_b_item3_title,
                score: TextA.win3_b_item3_score,
            },
            {
                icon: icons[3],
                title: TextA.win3_b_item4_title,
                score: TextA.win3_b_item4_score,
            },
        ],
        za: [
            {
                icon: icons[0],
                title: TextA.win3_b_item1_title,
                score: TextA.win3_b_item1_score,
            },
            {
                icon: icons[1],
                title: TextA.win3_b_item2_title,
                score: TextA.win3_b_item2_score,
            },
            {
                icon: icons[2],
                title: TextA.win3_b_item3_title,
                score: TextA.win3_b_item3_score,
            },
            {
                icon: icons[3],
                title: TextA.win3_b_item4_title,
                score: TextA.win3_b_item4_score,
            },
            {
                icon: icons[4],
                title: TextA.win3_b_item5_title,
                score: TextA.win3_b_item5_score,
            },
            {
                icon: icons[5],
                title: TextA.win3_b_item6_title,
                score: TextA.win3_b_item6_score,
            },
            {
                icon: icons[6],
                title: TextA.win3_b_item7_title,
                score: TextA.win3_b_item7_score,
            },
            {
                icon: icons[7],
                title: TextA.win3_b_item8_title,
                score: TextA.win3_b_item8_score,
            },
        ],
        kr: [
            {
                icon: icons[0],
                title: TextA.win3_b_item1_title,
                score: TextA.win3_b_item1_score,
            },
            {
                icon: icons[1],
                title: TextA.win3_b_item2_title,
                score: TextA.win3_b_item2_score,
            },
            {
                icon: icons[2],
                title: TextA.win3_b_item3_title,
                score: TextA.win3_b_item3_score,
            },
            {
                icon: icons[3],
                title: TextA.win3_b_item4_title,
                score: TextA.win3_b_item4_score,
            },
            {
                icon: icons[4],
                title: TextA.win3_b_item5_title,
                score: TextA.win3_b_item5_score,
            },
            {
                icon: icons[5],
                title: TextA.win3_b_item6_title,
                score: TextA.win3_b_item6_score,
            },
        ],
    };
    const listGift = GiftMap[locale] || GiftMap['us'];

    return (
        <div className={styles.WinC}>
            {!['jp'].includes(locale) && (
                <div className="WinC-box-a">
                    <div className="WinC-title">{TextA.win3_a_title}</div>
                    <div className="WinC-a-list">
                        {listCoupons.map((item: any, index: number) => (
                            <div key={index} className="WinC-a-item">
                                <div className="WinC-a-score">
                                    {['za', 'kr'].includes(locale) ? <IconcCouponsBig /> : <IconcCoupons />}
                                    <p className="score">{item.score}</p>
                                </div>
                                <div className="WinC-a-title">{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="WinC-box-b">
                <div className="WinC-title">{TextA.win3_b_title}</div>
                <div className="WinC-b-list">
                    {listGift.map((item: any, index: number) => (
                        <div key={index} className="WinC-b-item">
                            <div className="WinC-b-item-icon">{item.icon}</div>
                            <div className="WinC-b-item-r">
                                <div className="WinC-b-title">{item.title}</div>
                                <div className="WinC-b-score">{item.score}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {['jp'].includes(locale) && (
                <div className="WinC-box-b">
                    <div className="WinC-title">{TextA.win3_a_title}</div>
                    <div className="WinC-b-list">
                        {listCoupons.map((item: any, index: number) => (
                            <div key={index} className="WinC-b-item">
                                <div className="WinC-b-item-icon">{item.icon}</div>
                                <div className="WinC-b-item-r">
                                    <div className="WinC-b-title">{item.title}</div>
                                    <div className="WinC-b-score">{item.score}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
